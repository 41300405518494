import React, { useState, useEffect, useRef } from "react";
import {
  graphql,
  useStaticQuery,
} from "gatsby"
import Img from "gatsby-image"
import { navigate } from "gatsby"
import styled from 'styled-components';
import ReviewsPanel from 'src/components/landing-page/ReviewsPanel'
import colors from 'src/constants/colors'
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { identify, trackPage, trackEvent, getPropertiesFromLocation} from 'src/utilities/analytics'
import { createBetaUserSubscription } from 'src/utilities/http-requests'
import LoginModal from 'src/components/LoginModal'
import getFirebase from 'src/utilities/firebase'
import { createResume } from 'src/utilities/firestore'
import previewThemes from 'src/themes/previewThemes'
import Button from 'react-bootstrap/Button'

const pageName = 'Resume Templates'

// responsive image queries for theme template images
export const imageQuery = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "templates" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

// CONTAINER:
const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// HEADER:

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
`
const StyledH1 = styled('h1')`
  font-weight: 900;
  font-size: 3rem;
  @media only screen and (max-width: 576px) {
    font-size: 2rem;
  }
`
const StyledDescription = styled('p')`
  max-width: 45rem;
  margin-left: 20px;
  margin-right: 20px;
`

const Header = () => {
  return (
    <HeaderSection>
      <StyledH1>
        Resume Templates
      </StyledH1>
      <StyledDescription>
        Each resume template is expertly designed and optimized to get your resume through ATS filters. Stand out and get hired faster with tried and true resume templates.
      </StyledDescription>
    </HeaderSection>
  )
}

// THEME GRID:

const imageWidth = 400

const GridContainer = styled('section')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1440px;
  margin-right: 20px;
  margin-left: 20px;
`

const ItemContainer = styled('div')`
  // 3 columns per row by default
  width: 33%;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;


  @media only screen and (max-width: 768px) {
    // 2 columns per row on smaller devices like ipads
    width: 50%;
  }

  @media only screen and (max-width: 576px) {
    // 1 column1 per row on phones
    width: 100%;
  }
`

const ImageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ItemButton = styled(Button)`
  position: absolute;
  align-self: center;
`
const StyledImage = styled(Img)`
  width: 100%;
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 1px;
  border-radius: 3px;
`

const GridItem = ({
  id,
  name,
  description,
  imageData,
  onClick,
}) => {

  const [isHovering, setIsHovering] = useState(false)

  const _onClick = (e) => {

    // prevent click propagation from button to container
    if (e.defaultPrevented) return
    e.preventDefault()

    onClick({id})
  }

  return (
    <ItemContainer
      onClick={_onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <ImageContainer>
        <StyledImage
          fluid={imageData}
          alt={`${name} theme resume template`}
        />
        {
          isHovering &&
          <ItemButton
            variant='primary'
            onClick={_onClick}
          >
            Use This Template
          </ItemButton>
        }
      </ImageContainer>
      <h3 className='font-weight-bold'>
        {name}
      </h3>
      <p>
        {description}
      </p>
    </ItemContainer>
  )
}


const ThemeGrid = ({onClickItem}) => {

  // responsive images:
  const imageData = useStaticQuery(imageQuery)

  // create map
  // image filename: image data
  // to pull into ordered preview themes
  const imageFilenameToImageDataMap = {}
  imageData.allFile.edges.forEach(image => {
    const node = image.node
    imageFilenameToImageDataMap[node.base] = node.childImageSharp.fluid
  })

  return (
    <GridContainer>
      {
        previewThemes.map(theme => {
          return (
            <GridItem
              {...theme}
              id={theme.key}
              imageData={imageFilenameToImageDataMap[theme.imageFilename]}
              onClick={onClickItem}
            />
            )
        })
      }
    </GridContainer>
  )
}


const Content = () => {
  return (
    <section className="py-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8">
            <div>
              <h1
                style={ styles.subheader }
                className="text-black font-weight-bold"
              >
                Free Resume Templates
              </h1>
              {
                // intent of this page
                // this page should ideally come up for users who are looking for a resume builder
                // why?
                // not sure how to make a good resume. content, length, format,

                // tell people what the builder does
                // and how it fixes their problems

                // probably don't need that much content. people probably won't even see it.

                // they see the match via google snippet
                // then they just see the template grid

                // info above the fold should have some pitch

                // hit the keywords

                // include the words resume, template, free, cv, best, 2022, download, example, professional, format, builder, simple, skills, samples, objective, maker, help, job, summary, action words,
                /*
                cv maker, resume template, resume maker, resume builder, resume, cv template, resume templates, cv, resume io, cvmkr, free resume templates, online resume maker, cv maker free, free cv maker, resume.io, cv creator, cv builder, free resume builder, online cv maker, cv generator, resume examples, resume creator, topresume, cvmaker, free cv template, resume maker online, free resume maker, cv template free, cv online, cv templates, resume template free, cv builder free, resume builder free, resume online, cv template word, create cv, online cv maker free, resume builder online, create resume, resume maker free, top resume, cv maker online, resume builder online, questions to ask hiring manager, resume maker online, curriculum vitae, cover letter template, free resume templates, cv maker online free, online resume builder,

                */
              }
              <p>
                Hey there. So you're looking for a job?
                Your resume is your first opportunity to make an impression on the hiring manager.
                For most job applicants, it is their last.
                Did you know that ATS (Applicant Tracking Software, used in over 75% of recruitment processes) automatically filter out 3 of 4 resumes?
                These 75% of resumes are never seen by a human because they're filtered out for incorrect formatting or missing keywords.
                4 out of 5 applicants who submit a resume never get to interview.
                Hiring managers spend on average only 6 seconds looking at a resume.
                Does your resume have the right keywords to pass through ATS filters?
                Does it have the visual polish to catch the Hiring Manager's attention in those crucial few seconds?
              </p>

              <p>
                Fortunately, our free resume builder software can ensure your resume will be 1 of those 5 that makes it to interview.
                Made by Design and Resume Writing professionals, NextLevelResume gives you an optimized resume to beat ATS filters and stand out to Hiring Managers.
                Simply fill out your profile and we will generate a perfect, professionally designed resume.
                If you have LinkedIn, our LinkedIn import makes it a breeze to pull your information into your profile.
                Our content recommendations make filling out your profile as easy as online shopping.
                Simply point and click on suggestions from our bank of example skills and phrases proven to catch the attention of hiring managers and pass ATS filters.
              </p>

              <p>
                As you fill out your profile, you can preview in any of our free, professionally designed resume templates.
                Generally, free MS Word resume templates break shortly after you start filling in your information.
                But not NextLevelResume.
                Not only will NextLevelResume make your resume more likely to get you the job you're applying for, it makes the process of creating your resume much faster and easier!
              </p>
              <p>
                Once you've finished filling out your profile and selected a resume template that suits you, you can download to PDF format for free!
                PDFs are recruiters' and hiring managers' preferred file format for resumes.
                Resumes in other file formats can easily be changed on accident (and are often ruined) as different people in the recruitment process open or forward the resume between one another.
                With a PDF resume, you can be certain the recruiter or hiring manager will see on their screen exactly what you have crafted on yours.
              </p>


              <p>
                The Top 10 tips below are meant to guide you through picking the best resume template to land not just any job – but a dream position that will open new doors to a meaningful career. Let’s go!
              </p>

              <h2
                style={ styles.subheader }
                className="text-black font-weight-bold"
              >
                Top 10 Tips For Picking a Resume Template
              </h2>
              <ol>
                <li>
                  Understand the standards of your industry
                </li>
                <li>
                  Familiarize yourself with the tone of your company
                </li>
                <li>
                  Decide on the self-image you want to present
                </li>
                <li>
                  Reflect on which sections are most important for your career
                </li>
                <li>
                  Think about where you’ll need to add the most writing
                </li>
                <li>
                  Evaluate the presentation and attractiveness of the template
                </li>
                <li>
                  Pay attention to whether icons and color are appropriate for your industry
                </li>
                <li>
                  Check if you’ll have space for a photo or logo if it’s needed
                </li>
                <li>
                  Assess the most eye-catching section and make sure it matches your needs
                </li>
                <li>
                  Choose the resume that captures your personal style and feels right for you!
                </li>
              </ol>
              <p>
                If there was one resume template that worked for everyone – well, our website would be much, much smaller. But the truth is, a variety of templates are important to help capture the uniqueness of each candidate. And as beautiful as each resume template might be, a hiring manager doesn’t want to see the same one 100 times in a row. At the end of the day, the choice comes down to you. It’s important to evaluate the level of professionalism in your industry and at the company you’re applying to and decide if a modern, stylish resume or a more traditional one would be in line with those standards.
              </p>
              <p>
                Beyond that, it’s about highlighting the experiences and abilities that make you unique. In some industries, a pop of color or a friendly photo can do the trick. In others, you’ll have to rely on your expert writing to sell yourself as a top candidate
              </p>
              <p>
                Let’s say you pick a template but find out the skills section just doesn’t have enough space for all your talents. The best part about using our resume creator is that you can switch between templates with ease. So go on, <strong>pick a resume template and test it out</strong>. Changing your mind is never a problem.
              </p>

              <h2
                style={ styles.subheader }
                className="text-black font-weight-bold"
              >
                What makes the best resume template
              </h2>
              <p>
                Most resumes contain the same basic elements: contact info, a profile summary and sections for experience, education, skills and achievements. While our builder tool makes it easy to add or delete sections based on your industry and needs, it’s a good idea to start with a resume template that offers you the best chances at customization. The best layouts make your information attractive and clear, with the right balance of text and white space. Color and icons should be used depending on the best practices for your industry, and whether or not to include a photo often depends on the country where you intend to work.
              </p>
              {
                // TODO: below item should have a link to resume builder landing page
              }
              <p>
                Our resume templates are expertly designed so that all your information fits on one page (strongly recommended for most industries and experience levels), without looking crammed or cluttered. Our resume builder tool, along with our guides, will help you create the best layout that makes the most of each element.
              </p>
              <p>
                The job hunt is a fierce competition, and you’ll want to take on this challenge from all angles. That means applying to multiple positions at once. Tailoring your resume for each and every opening is not only highly suggested, it’s the reality of the 22st century hiring process. But that doesn’t mean it has to be time consuming. With our subscription service, you can edit, update and change your resume as many times as you’d like and download each new version as a .pdf  to make the application process run even smoother.
              </p>

              <h2
                style={ styles.subheader }
                className="text-black font-weight-bold"
              >
                CV Templates for every career
              </h2>
              <p>
                As a candidate, you are a unique individual with the skills and experience to get the job done. So why shouldn’t your resume be just as interesting as you are? When it comes to convincing an employer to hire you, your resume is the best way to tell your story and make a strong first impression. That’s why we’ve curated a collection of dozens of expertly-designed CV templates to fit a variety of fields and career levels. To make choosing a template even easier, we’ve divided our collection into five types: professional, modern, creative, simple and basic. These groupings offer a great starting point to explore the CV templates best suited for your field. Then, if nothing catches your eye, try another collection to find the right style and add your personal flair.
              </p>

              {
                // below is where the sections for each resume category: modern, professional, creative, simple, would go
              }

            </div>
          </div>
        </div>
      </div>
    </section>


    )
}

const StyledReviewsPanel = styled(ReviewsPanel)`
  background-color: var(--neutral-10);
`

const ResumeTemplates = ({location}) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false)

  // mutable references to most up to date values for selected theme and current user
  const selectedThemeRef = useRef()
  const currentUserRef = useRef()

  const firebase = getFirebase();
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      currentUserRef.current = user
      attemptCreateResume()
      identify(user)
    });
    }, [firebase]);

  const onRequestClose = () => {
    setLoginModalIsOpen(false)
  }
  const signInSuccessWithAuthResult = () => {
    // have logic here to handle navigation after sign in
    // so override default login modal signin success redirect with no-op
    return false
  }
  const attemptCreateResume = () => {
    // if there is current user and selected theme
    // create new resume with theme then navigate to that resume's page
    // the control flow can get complicated since i need to
    // get most up to date values from within the firebase auth state change callback
    // this function with refs allows it to always check most recent values
    // and attempt to create resume once both
    // - theme is selected
    // - user is logged in
    if (!selectedThemeRef.current || !currentUserRef.current) {
      return
    }

    const resumeInfo = {
      metadata: {
        theme: selectedThemeRef.current
      }
    }

    createResume({
      ownerUID: currentUserRef.current.uid,
      resumeInfo
    })
    .then(docRef => {
      navigate(`/resumes/${docRef.id}`)
    })
    .catch(error => console.error("Error creating resume: ", error))
  }


  useEffect(() => {
    const urlProperties = getPropertiesFromLocation(location)
    trackPage(
      pageName,
      {
        ...urlProperties,
      }
    )
  }, [])

  // when you select a slide
  // you can already be logged in or not
  // if you are logged in
  // select a theme, create resume, and continue
  // if you are not logged in
  // force either managed login or anonymous
  // then create resume and continue
  // if is beta, create beta user subcription

  const onClickItem = ({id}) => {
    selectedThemeRef.current = id
    if (!currentUser) {
      setLoginModalIsOpen(true)
    }
    attemptCreateResume()
    trackEvent('clicked_select_template', {
      page: pageName,
      template_id: id,
    })
  }

  return (
    <Layout className="bg-white">
      <SEO title='Resume Templates | Free Download | Best of 2022'/>
      <AnalyticsHelmet/>
      <LoginModal isOpen={loginModalIsOpen} shouldAllowSkipLogin={true} {...{ firebase, onRequestClose, signInSuccessWithAuthResult }}/>
      <Container>
        <Header/>
        <ThemeGrid
          onClickItem={onClickItem}
        />
        <StyledReviewsPanel/>
        <Content/>
      </Container>
    </Layout>
  )
}


const styles = {
  header: {

  },
  subheader: {
    alignSelf: 'center',
    marginTop: '1rem',
    cursor: 'default',
    marginTop: 0,
  },
}

export default ResumeTemplates
