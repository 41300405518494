import React, {useState, useEffect, useRef} from "react"
import styled, { css } from 'styled-components';
import ChevronLeft from 'src/images/icons/chevron-left.svg'
import Slider from "react-slick";
import 'src/styles/slick.css'
import 'src/styles/slick-theme.css'
import 'src/styles/slick-reviews-panel.css'
import 'src/styles/reviews-panel.css'
import FiveStar from 'src/images/icons/ratings/five-star.svg'
import { useMediaQuery } from 'react-responsive'


const ArrowButton = ({ArrowClass, className, style, onClick}) => {
  const arrowWidth = 18
  const blueCircleStyle = {
    borderRadius: '50%',
    width: 24,
    height: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }
  return (
    <div style={{...style, ...blueCircleStyle}} onClick={onClick} className={className}>
      <ArrowClass width={arrowWidth} height={arrowWidth} fill='white' stroke='gray'/>
    </div>
  )
}

const PrevButton = ({className, style, onClick}) => {
  return <ArrowButton ArrowClass={ChevronLeft} {...{className, style, onClick}}/>
}
const NextButton = ({className, style, onClick}) => {
  // same svg as prev, flipped horizontally
  return (
    <ArrowButton
      ArrowClass={ChevronLeft}
      style={{
        ...style,
        transform: 'translate(0, -50%) rotate(180deg)'
      }}
      {...{className, onClick}}
    />
  )
}

const Container = styled('section')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  @media only screen and (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media only screen and (max-width: 578px) {
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const StyledFiveStar = styled(FiveStar)`
`

const SummaryContainer = styled('div')`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 578px) {
    margin-bottom: 20px;
  }
`

const SummaryHeader = styled(`h3`)`
  margin-top: 10px;
`

const SummaryFooter = styled('h3')`
  margin-top: 10px;
`

const StarRating = ({
  rating,
  width,
}) => {
  // only handles 4, 4.5, and 5 star ratings ;)

  const ratingMap = {
    4: 'tp-stars--4',
    4.5: 'tp-stars--4 tp-stars--4--half',
    5: 'tp-stars--5'
  }

  return (
    <StyledFiveStar
      className={`tp-stars ${ratingMap[rating] || 'tp-stars--4'}`}
      width={width}
    />
  )
}

// shows the aggregated rating
const Summary = () => {
  return (
    <SummaryContainer>
      <SummaryHeader>
        Rated 4.6 out of 5
      </SummaryHeader>
      <StarRating
        rating={4.5}
        width={162}
      />
      <SummaryFooter>
        Excellent
      </SummaryFooter>
    </SummaryContainer>
  )
}

const ReviewContainer = styled('div')`
  height: 120px;
  display: flex;
  flex-direction: column;
  margin: 0 15px 10px 0;
  padding: 0 0 0 20px;
`

const ReviewTopContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 12px;
`

const ReviewTimeAgoText = styled('div')`
  color: rgba(0,0,0,0.6);
  font-size: 12px;
`

const ReviewHeader = styled('div')`
  font-size: 14px;
  height: 22px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 6px;
  word-wrap: break-word;
  white-space: nowrap;
  margin-bottom: 6px;
`

const ReviewText = styled('div')`
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-height: 32px;
  line-height: 16px;
  margin: 0 0 8px;
`

const ReviewerName = styled('div')`
  color: rgba(0,0,0,0.6);
  font-size: 12px;
`

const Review = ({
  rating,
  header,
  text,
  timeAgoText,
  name,
}) => {
  return (
    <ReviewContainer>
      <ReviewTopContainer>
        <StarRating
          rating={rating}
          width={99}
        />
        <ReviewTimeAgoText>
          {timeAgoText}
        </ReviewTimeAgoText>
      </ReviewTopContainer>
      <ReviewHeader>
        {header}
      </ReviewHeader>
      <ReviewText>
        {text}
      </ReviewText>
      <ReviewerName>
        {name}
      </ReviewerName>
    </ReviewContainer>
  )
}

const PanelContainer = styled('div')`
  width: calc(100% - 200px);
  padding: 0 40px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media only screen and (max-width: 578px) {
    width: 100%
  }
`

// holds all the reviews
const Panel = () => {

  // show fewer slides as screen size decreases

  const shouldShowOne = useMediaQuery({maxWidth: 880,})
  const shouldShowTwo = useMediaQuery({minWidth: 881, maxWidth: 1124,})
  const shouldShowThree = useMediaQuery({minWidth: 1125, maxWidth: 1408,})

  let slidesToShow = 4

  if (shouldShowThree) {
    slidesToShow = 3
  } else if (shouldShowTwo) {
    slidesToShow = 2
  } else if (shouldShowOne) {
    slidesToShow = 1
  }

  const settings = {
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      infinite: true,
      prevArrow: <PrevButton/>,
      nextArrow: <NextButton/>,
    }

  return (
    <PanelContainer>
      <Slider {...settings}>
        <Review
          rating={5}
          header='Easy and to the point'
          text="Easy and to the point. Didn't take too much time."
          timeAgoText='6 hours ago'
          name='Jorge Sanchez'
        />
        <Review
          rating={5}
          header='a really great service'
          text="loved it! thank you so much!"
          timeAgoText='23 hours ago'
          name='donna stanel'
        />
        <Review
          rating={5}
          header='Awesome'
          text="My favorite so far for writing resumes. Very easy to use. You will not regret it."
          timeAgoText='1 day ago'
          name='Nate H'
        />
        <Review
          rating={4}
          header="Wasn't the easiest to use on my phone"
          text="But I guess writing a resume on my phone without this would have been way harder probably lol"
          timeAgoText='1 day ago'
          name='Victoria'
        />
        <Review
          rating={5}
          header='I use it every time I have to apply for a job'
          text="Makes your resume look so sleek and offers great tips for what to include."
          timeAgoText='2 days ago'
          name='Derek H. Scott'
        />
        <Review
          rating={5}
          header='Excellent'
          text="Excellent tool for taking your resume to the next level. Simple and fast."
          timeAgoText='2 days ago'
          name='Suraj Patel'
        />
        <Review
          rating={5}
          header='Had to write a resume'
          text="And NextLevelResume did all the work for me. Thank god!"
          timeAgoText='2 hours ago'
          name='Zola Athas'
        />
      </Slider>
    </PanelContainer>
  )
}

const ReviewsPanel = ({
  className,
}) => {

  return (
    <Container
      {...{
        className,
      }}
    >
      <Summary/>
      <Panel/>
    </Container>
  )

}

export default ReviewsPanel
